<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>动账记录</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="accountType">
            <div style="display: inline-block" class="divSpan">用户身份：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.accountType"
                :options="accountStatus"
                :optionKey="accountStatuskeys"
                Splaceholder="请选择"
                @clear="getList"
                style="width: 140px"
              ></ELselect>
            </div>
          </el-form-item>

          <el-form-item prop="inOutType">
            <div style="display: inline-block" class="divSpan">收支类型：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.inOutType"
                :options="inOutStatus"
                :optionKey="accountStatuskeys"
                Splaceholder="请选择"
                @clear="getList"
                style="width: 140px"
              ></ELselect>
            </div>
          </el-form-item>

          <el-form-item prop="userID">
            <div style="display: inline-block" class="divSpan">用户ID：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.userID"
                clearable
                @clear="getList"
                style="width: 200px"
              >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item prop="waterNo">
            <div style="display: inline-block" class="divSpan">流水号：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.waterNo"
                clearable
                @clear="getList"
                style="width: 300px"
              >
              </el-input>
            </div>
          </el-form-item>

          <el-form-item prop="orderNo">
            <div style="display: inline-block" class="divSpan">
              订单/工单编号：
            </div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入搜索的编号"
                v-model.trim="queryinfo.orderNo"
                clearable
                @clear="getList"
                style="width: 220px"
              >
              </el-input>
            </div>
          </el-form-item>

          <el-form-item prop="payTime">
            <div style="display: inline-block" class="divSpan">动账时间：</div>
            <div style="display: inline-block">
              <el-date-picker
                type="datetimerange"
                v-model="queryinfo.payTime"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="截止日期"
                @change="dateSerach"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item prop="amountLimit">
            <div style="display: inline-block" class="divSpan">金额范围：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.amountLimit"
                :options="amountLimitList"
                :optionKey="accountStatuskeys"
                Splaceholder="请选择金额范围"
                @clear="getList"
                style="width: 140px"
              ></ELselect>
            </div>
          </el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
          <el-button type="info" @click="exportS">导出</el-button>
        </el-form>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="transcationList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="tradeNo"
            label="订单/工单编号"
            min-width="160px"
          ></el-table-column>
          <el-table-column
            prop="transactionNo"
            label="交易流水号"
            min-width="160px"
          ></el-table-column>
          <el-table-column
            prop="userId"
            label="用户ID"
            min-width="80px"
          ></el-table-column>
          <el-table-column
            prop="changeBalance"
            label="动账金额(元)"
            min-width="100px"
          >
            <template slot-scope="{ row }">
              <span
                v-if="(row.changeBalance || '').startsWith('-')"
                style="color: red"
                >{{ row.changeBalance | money }}</span
              >
              <span v-else style="color: green">{{
                row.changeBalance | money
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="balance"
            label="账户余额(动账后)"
            min-width="110px"
          >
            <template slot-scope="{ row }">
              <span style="color: green">{{ row.balance | money }}</span>
            </template></el-table-column
          >
          <el-table-column label="动账摘要" min-width="100px" prop="summary">
          </el-table-column>
          <el-table-column
            prop="changeDate"
            label="动账时间"
            min-width="150px"
          ></el-table-column>
          <el-table-column label="操作" min-width="100px" fixed="right">
            <template slot-scope="scope">
              <el-link size="mini" type="primary" @click="viewDetail(scope.row)"
                >查看详情</el-link
              >
            </template>
          </el-table-column>

          <!-- <el-table-column
            prop="identity"
            label="用户身份"
            min-width="70px"
          ></el-table-column>
          <el-table-column
            prop="feeType"
            label="收支类型"
            min-width="80px"
          ></el-table-column>
          <el-table-column
            prop="accountName"
            label="账户名称"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="accountNo"
            label="账户账号"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="attach"
            label="备注"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="beforeBalance"
            label="动账前金额(元)"
            min-width="110px"
          ></el-table-column> -->
        </el-table>
      </el-row>

      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 查看对话框-->
    <PayCordDetail ref="payCordDetail" />
    <!-- 导出 -->
    <div v-if="exportDialogVisible">
      <ExportDialog
        :visible.sync="exportDialogVisible"
        title="导出动账记录"
        @confirmExport="confirmExport"
        @closeExportDialog="closeExportDialog"
      >
      </ExportDialog>
    </div>
  </div>
</template>
<script>
import ELselect from "@/components/myself/el_select";
import { exportRecord } from "@/components/myself/DownloadFile";
import ExportDialog from "@/components/myself/exportDialog.vue";
import { getAccountDetailed } from "@/api/cloudmarket/finance";
import PayCordDetail from "@/components/Home/cloudmarket/ordermanager/payRecordDetail.vue";
import { SearchMoneyList } from "@/libs/static";

export default {
  components: { ELselect, ExportDialog, PayCordDetail },
  filters: {
    money: function (value) {
      if (!value) return "￥0.00";
      // 将 + 号转换为 +￥； - 号转换为 -￥
      return value.replace("+", "+￥").replace("-", "-￥");
    },
  },
  data() {
    return {
      queryinfo: {
        pageNum: 1,
        pageSize: 20,
        payTime: "",
        accountType: "",
        inOutType: "",
        orderNo: "",
        userID: "",
        waterNo: "",
        amountLimit: "",
      },
      total: 0,

      accountStatus: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "2",
          label: "平台",
        },
        {
          value: "1",
          label: "商家",
        },
        {
          value: "0",
          label: "消费者",
        },
      ],
      accountStatuskeys: {
        value: "value",
        label: "label",
        label2: "",
      },
      inOutStatus: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "收入",
        },
        {
          value: "1",
          label: "支出",
        },
      ],
      amountLimitList: SearchMoneyList,

      transcationList: [],

      exportDialogVisible: false,
      detailInfo: {},
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let time1 = this.queryinfo.payTime ? this.queryinfo.payTime[0] : "";
      let time2 = this.queryinfo.payTime ? this.queryinfo.payTime[1] : "";
      let querise = {
        startTime: time1,
        endTime: time2,
        tradeNo: this.queryinfo.orderNo,
        amount: this.queryinfo.amountLimit,
        transactionNo: this.queryinfo.waterNo,
        userIdFrom: this.queryinfo.userID,
        feeType: this.queryinfo.inOutType,
        position: this.queryinfo.accountType,
      };
      const res = await getAccountDetailed(
        this.queryinfo.pageNum,
        this.queryinfo.pageSize,
        querise
      );
      if (res.status !== 200) return this.$message.error("获取失败");
      if (res.data.status !== 200) return this.$message.error(res.data.message);
      this.transcationList = res.data.data.list;
      this.total = res.data.data.total;
    },
    dateSerach() {
      this.getList();
    },
    clean() {
      this.$refs.queryinfoRef.resetFields();
      this.getList();
    },
    seachChange() {
      this.queryinfo.pageNum = 1;
      this.getList();
    },
    handleSizeChange(newSize) {
      this.queryinfo.pageSize = newSize;
      this.getList();
    },
    handleCurrentChange(newPage) {
      this.queryinfo.pageNum = newPage;
      this.getList();
    },

    async viewDetail(para) {
      this.$refs.payCordDetail.init(para);
    },

    confirmExport(para) {
      let time1 = this.queryinfo.payTime ? this.queryinfo.payTime[0] : "";
      let time2 = this.queryinfo.payTime ? this.queryinfo.payTime[1] : "";
      let isAll = 0;
      if (para !== 1) {
        isAll = 1;
      }

      let quiese = {
        isAll: isAll,
        pageNum: this.queryinfo.pageNum,
        pageSize: this.queryinfo.pageSize,
        startTime: time1,
        endTime: time2,
        tradeNo: this.queryinfo.orderNo,
        transactionNo: this.queryinfo.waterNo,
        userIdFrom: this.queryinfo.userID,
        feeType: this.queryinfo.inOutType,
        position: this.queryinfo.accountType,
      };

      exportRecord(quiese, "动账记录", 1);
      this.closeExportDialog();
    },
    exportS() {
      this.exportDialogVisible = true;
    },
    closeExportDialog() {
      this.exportDialogVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item__content {
  margin-right: 30px;
}
</style>
