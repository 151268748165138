import axios from "@/libs/request";
/**
 * 获取动账记录详情
 */
export const GetAccountDetailById = (id, config = {}) => {
  return axios.request({
    url: `/finance/getAccountDetailById/${id}`,
    method: "get",
    ...config,
  });
};
