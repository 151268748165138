<template>
  <el-drawer
    :close-on-click-modal="false"
    title="动账记录详情"
    direction="rtl"
    :visible.sync="visible"
    style="text-align: left"
    size="80%"
  >
    <div class="drawer" style="padding: 20px 60px">
      <el-row :gutter="30" style="text-align: left; left: 20px">
        <!-- 基本信息 -->
        <el-descriptions
          title="基本信息"
          :column="4"
          border
          direction="vertical"
        >
          <el-descriptions-item label="动账时间">
            {{ detailInfo.changeDate || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="订单/工单编号">
            {{ detailInfo.tradeNo || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="交易流水号">
            {{ detailInfo.tradeNo || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label=""></el-descriptions-item>
          <el-descriptions-item label="用户账号">
            {{ detailInfo.transactionNo || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="收付款账户">
            {{ detailInfo.accountNo || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="账户类型">
            {{ detailInfo.accountKind == '1' ? '支付宝' : detailInfo.accountKind == '2' ? '微信' : "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="动账摘要">
            {{ detailInfo.summary }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          title="动账信息"
          :column="4"
          border
          direction="vertical"
          style="margin-top: 40px"
        >
          <el-descriptions-item label="收支类型">
            <el-tag v-if="detailInfo.feeType == '0'" type="success" size="mini">
              收入
            </el-tag>
            <el-tag
              v-else-if="detailInfo.feeType == '1'"
              type="danger"
              size="mini"
            >
              支出
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="动账金额">
            <span
              v-if="(detailInfo.changeBalance || '').startsWith('-')"
              style="color: red"
            >
              {{ detailInfo.changeBalance | moneyFormat }}
            </span>
            <span v-else style="color: green">
              {{ detailInfo.changeBalance | moneyFormat }}
            </span>
          </el-descriptions-item>
          <el-descriptions-item label="用户余额(动账前)">
            ￥{{ detailInfo.beforeBalance || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="用户余额(动账后)">
            ￥{{ detailInfo.balance || "-" }}
          </el-descriptions-item>
        </el-descriptions>
      </el-row>
    </div>
  </el-drawer>
</template>

<script>
import { GetAccountDetailById } from "@/api/service/finance";

export default {
  data() {
    return {
      visible: false,
      detailInfo: {},
    };
  },
  filters: {
    moneyFormat(val = "") {
      if (val.startsWith("-") || val.startsWith("+")) {
        val = val.slice(0, 1) + "￥" + val.slice(1);
      }
      return val;
    },
  },

  methods: {
    init(data) {
      this.visible = true;
      this.$nextTick(() => {
        this.viewDetail(data);
      });
    },

    async viewDetail(para) {
      const res = await GetAccountDetailById(para.id, {
        loading: {
          target: document.querySelector(".drawer"),
        },
      });
      console.log("res:", res);
      this.detailInfo = { ...para, ...res };
    },
  },
};
</script>
